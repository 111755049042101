
body {
  font:300 15px/1.4 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

header {
  background-color: #0096c7;
  color: white;
  padding: 20px;
  font-size: 2rem;
  text-align: center;
}

.content {
  max-width:60em;
  margin:0 auto;
}

.App-link {
  color: #61dafb;
}


/* Form */
.form{
	max-width: 500px;
	padding: 20px 12px 10px 20px;
	font: 13px Arial, Helvetica, sans-serif;
}
.form-heading{
	font-weight: bold;
	font-style: italic;
	border-bottom: 2px solid #ddd;
	margin-bottom: 20px;
	font-size: 15px;
	padding-bottom: 3px;
}
.form label{
	display: block;
	margin: 0px 0px 15px 0px;
}
.form label > span{
	width: 100px;
	font-weight: bold;
	float: left;
	padding-top: 8px;
	padding-right: 5px;
}
.form span.required{
	color:red;
}
.form .tel-number-field{
	width: 40px;
	text-align: center;
}
.form input.input-field, .form .select-field{
	width: 48%;	
}
.form input.input-field, 
.form .tel-number-field, 
.form .textarea-field, 
 .form .select-field{
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	border: 1px solid #C2C2C2;
	box-shadow: 1px 1px 4px #EBEBEB;
	-moz-box-shadow: 1px 1px 4px #EBEBEB;
	-webkit-box-shadow: 1px 1px 4px #EBEBEB;
	border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	padding: 7px;
	outline: none;
}
.form .input-field:focus, 
.form .tel-number-field:focus, 
.form .textarea-field:focus,  
.form .select-field:focus{
	border: 1px solid #0C0;
}
.form .textarea-field{
	height:100px;
	width: 55%;
}
.form input[type=submit],
.form input[type=button]{
	border: none;
	padding: 8px 15px 8px 15px;
  background: #90e0ef;
	color: #000;
	box-shadow: 1px 1px 4px #DADADA;
	-moz-box-shadow: 1px 1px 4px #DADADA;
	-webkit-box-shadow: 1px 1px 4px #DADADA;
	border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
}
.form input[type=submit]:hover,
.form input[type=button]:hover{
	background: #0096c7;
	color: #fff;
}

/* Delete button */

.delete {
	border: none;
	padding: 8px 15px 8px 15px;
  background: #c1121f;
	color: #fff;
	box-shadow: 1px 1px 4px #DADADA;
	-moz-box-shadow: 1px 1px 4px #DADADA;
	-webkit-box-shadow: 1px 1px 4px #DADADA;
	border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
}
.delete:hover {
	background: #780000;
	color: #fff;
}